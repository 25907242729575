@import 'src/typography';
@import 'src/mixins';
@import 'src/theme';

.Wrapper {
  @include d-flex-a-center;
  flex-direction: column;
}

.Indeterminate {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  animation: rotate 1s infinite linear;
  border: 0.25rem solid black;
  border-right-color: white;
}

.Determinate {
  height: 0.25rem;
  border-radius: 0;
  width: 100%;
  background-color: black;
  & div {
    background-color: black;
    height: 100%;
    transition: all 300ms;
  }
}

.Title {
  @include typography-heading-6;
  margin-bottom: 0.5rem;
}

.Subtitle {
  @include typography-caption-1;
  margin-bottom: 1.5rem;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
