//For future use:
//https://dev.to/alemesa/10-awesome-sass-scss-mixins-5ci2
//https://engageinteractive.co.uk/blog/top-10-scss-mixins
//https://www.developerdrive.com/10-best-sass-mixins-for-web-developers/

@use 'sass:math';
@import './src/theme.scss';

//Media query mixin for defining styles for a certain window size
@mixin mq($width, $type: min) {
  @if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);
    @if $type == max {
      $width: $width - 1;
    }
    @media only screen and (#{$type}-width: #{$width}px) {
      @content;
    }
  }
}

//Used for defining the font size in the typography. Forces you to set line-height as well
@mixin font-size($font-size, $line-height) {
  font-size: math.div($font-size, 16) * 1rem;
  line-height: math.div($line-height, 16) * 1rem;
}

//Used for defining breaking text with three ending dots
@mixin text-breaking-with-three-dots {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

//Mixins for flex layouts
@mixin d-flex-a-flex-start-j-space-between {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
@mixin d-flex-a-center-j-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
@mixin d-flex-a-center-j-flex-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
@mixin d-flex-a-center-j-flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
@mixin d-flex-a-center-j-space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@mixin d-flex-a-flex-start-j-space-between {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
@mixin d-flex-a-flex-end-j-space-between {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

@mixin d-flex-a-center {
  display: flex;
  align-items: center;
}
@mixin d-flex-a-flex-start {
  display: flex;
  align-items: flex-start;
}
@mixin d-flex-a-flex-end {
  display: flex;
  align-items: flex-end;
}

@mixin d-flex-j-space-between {
  display: flex;
  justify-content: space-between;
}
@mixin d-flex-j-flex-end {
  display: flex;
  justify-content: flex-end;
}

@mixin backdrop {
  position: fixed;
  z-index: $backdrop-z-index;
  background-color: rgba(#05131d, 0.6);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  transition: all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition-property: opacity, visibility;
}

@mixin gap($value, $dir: 'left') {
  /* Gap alternative because of Safari */
  @if $dir == 'left' {
    & > * + * {
      margin-left: $value;
    }
  } @else {
    & > * + * {
      margin-top: $value;
    }
  }
}
