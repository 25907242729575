$default-font: 'Nunito Sans', -apple-system, BlinkMacSystemFont, Helvetica,
  Arial, sans-serif;

$backdrop-z-index: 4;
$snackbar-z-index: 3;

/* Breakpoints */
$breakpoints: (
  'phone': 400,
  'phone-wide': 480,
  'phablet': 560,
  'tablet-small': 640,
  'tablet': 768,
  'tablet-wide': 1024,
  'desktop': 1248,
  'desktop-wide': 1440,
);

$dark-blue: #011E3B;
$content-quartary: #354854;
$background-color: #FBFCFE;
$gedeon-white: #FFFFFF;
$background-color-primary: #F2F6FA;
$error-delete-color: #FF3B30;
$light-gray-gedeon: #DDDDDE;
$no-name-color-1: #344B62;
$light-blue-trans: #BFCDDF;
$color-content-secondary: #9E9E9E;
$color-gedeon: #226BC1;
$color-negative: #bd0910;

:root {
  --color-positive: #226BC1;
  --color-positive-highlight: #003470;
  --color-accent: #A1C7FA;
  //--color-background-primary: $background-color-primary; // TODO: this color is not good
  --color-background-secondary: #0E2637;
  --color-content-secondary: #9E9E9E;
  --color-content-quartary: #354854;
  --color-positive-washed: #BFCDDF;
}