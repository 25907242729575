@import 'src/typography';
@import 'src/theme';
@import 'src/mixins';

.Backdrop {
  @include backdrop;
  @include d-flex-a-center-j-center;
}

.BackdropAnimateIn {
  visibility: visible;
  opacity: 1;
}
