@import 'src/typography';
@import 'src/mixins';

.profileButton {
  display: flex;
  cursor: pointer;

  > div {
    display: flex;
    flex-direction: column;
    margin-right: 12px;

    @include mq('phone-wide', 'max'){
      flex-direction: row;
    }

    > span:first-child {
      @include typography-caption-2;
      color: $dark-blue;
    }

    > span:last-child {
      @include typography-caption;
      color: $content-quartary;
    }
  }
}

.footer {
  width: 156px;
}

.dropdown {
  margin-top: 6px;
}