@import 'theme';
@import 'mixins';
@import 'typography';

html,
body {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: $default-font;
  vertical-align: baseline;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

#root {
  height: 100%;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

*, *::before, *::after {
  box-sizing: inherit;
}

body, h1, h2, h3, h4, h5, h6, p, ol, ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

img {
  max-width: 100%;
  height: auto;
}
